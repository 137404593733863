<template>
  <div class="tenant-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>
<script>
  let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //房客支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("房客格式不对"));
      } else {
        callback();
      }
    }
  };
  let validRoomNo = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
      let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
      if (!reg.test(value)) {
        callback(new Error("房号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validMobile = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("手机号码格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        rentalStyle: "0", // 0长租 1短租
        opt: {
          title: "租约列表",
          exportName: '',
          getApartmentListflag: true,
          search: [{
              key: "contractorTime",
              label: "签约日期",
              timeType: "yyyy-MM-dd HH:mm:ss",
              format: "yyyy-MM-dd",
              type: "daterange"
            },
            {
              key: "rentTime",
              label: "租约日期",
              timeType: "yyyy-MM-dd HH:mm:ss",
              format: "yyyy-MM-dd",
              type: "daterange"
            },
            {
              key: "rentType",
              label: "租约类型",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: '全部',
                    value: ''
                  },
                  {
                    label: '自订/长租',
                    value: '1'
                  },
                  {
                    label: '自订/短租',
                    value: '2'
                  },
                  {
                    label: '自订/钟点房',
                    value: '5'
                  },
                  {
                    label: '排房/长租',
                    value: '3'
                  },
                  {
                    label: '排房/短租',
                    value: '4'
                  },
                  {
                    label: 'PMS排房',
                    value: '6'
                  },
                ]
              }
            },
            {
              key: "rentStatus",
              label: "租约状态",
              type: "select-no-filterable",
              opt: {
                list: _this.leaseStatusOptions
              }
            },
            {
              key: "houseId",
              label: "房源",
              type: "select",
              opt: {
                list: [],
                change(data, cb) {
                  _this.opt.search.forEach(item => {
                    if (item.key == 'buildingNo') {
                      cb({
                        value: "",
                        key: item.key,
                      })
                      item.opt.list = [];
                    }
                  })
                  _this.getBuildList(data);
                }
              }
            },
            {
              key: "buildingNo",
              label: "楼栋",
              type: "select",
              opt: {
                list: [],
                parent: "houseId",
              }
            },
            {
              key: "roomNo",
              label: "房号",
              maxlength: 24,
              rules: [{
                validator: validRoomNo,
                trigger: ['blur']
              }]
            },
            {
              key: "name",
              label: "房客",
              maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
            },
            {
              key: "userPhone",
              label: "手机号码",
              maxlength: 11,
              rules: [{
                validator: validMobile,
                trigger: ['blur']
              }]
            },
          ],
          columns: [{
              label: "签约时间",
              key: "createDate"
            },
            {
              label: "房源信息",
              key: "orderName"
            },
            {
              label: "姓名",
              key: "name",
            },
            {
              label: "手机号码",
              key: "userPhone"
            },
            {
              label: "入住人数",
              key: "tenantCount"
            },
            {
              label: "租约类型",
              key: "reservationTypeStr",
              width: 120
            },
            // {
            //   label: "签约租金",
            //   key: "rentFeeStr",
            //   width: 120
            // },
            {
              label: "开始日期",
              key: "startDateStr",
              width: 120
            },
            {
              label: "截止日期",
              key: "checkoutDate",
              width: 120
            },
            {
              label: "租赁状态",
              key: "leaseStateStr"
            },
            {
              label: "操作",
              key: "action",
              type: "action",
              opt: {
                list: [{
                  label: "详情",
                  on(row) {
                    // _this.$router.push(
                    //   "/main/leaseManagement/detailTab/show/" +
                    //   row.userId +
                    //   // "?roomid=" +
                    //   // data +
                    //   "?type=" +
                    //   _this.rentalStyle +
                    //   "&userid=" +
                    //   row.userId
                    // );
                    _this.$router.push({
                      path: "/main/leaseManagement/detailTab/show/" + row.leaseContractId, 
                      query: {
                        roomId: row.roomId,
                        leaseType: row.leaseType,
                        leaseFrom: row.leaseFrom,
                        outState: row.outState,
                        isPlan: row.isPlan,
                        userId: row.userId,
                        state: row.state,
                        leaseState: row.leaseState,
                        startDate: row.startDate,
                        leaseId: row.leaseId,
                        entrance: 1, //1-租约列表  2-房客列表
                        validContractId: row.validContractId,
                      }
                    });
                  }
                }]
              }
            }
          ],
          buttons: [{
            name: "批量退房",
            type: 2,
            color: "primary",
            on(row) {
              const h = _this.$createElement;
              _this.$msgbox({
                title: "确定提示",
                message: h("p", null, [
                  h(
                    "span", {
                      style: "color: #333;font-size: 16px;margin-left:98px"
                    },
                    "您确定要退房吗？"
                  ),
                ]),
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消"
              }).then(action => {
                _this.batchesCheckOut(row);
              })
            }
          }],
          exportButtons: [{
            type: "Export",
            url: this.EXport + "/tenant-service/v1/lease/export",
            listName: "租约列表"
          }],
        }
      };
    },
    created() {
      console.log("tenant-list created!!");
      //房东 或 有排房操作权限的房管员 才显示排房按钮
      if (!this.getLandlordFlage() && !this.getHousekeeperPlanAndQuitFlage()) {
        console.log('不是房东、没有排房权限的房管员')
        this.opt.buttons = [];
      }
    },
    activated() {
      this.opt.search.forEach(item => {
        if(item.key == 'houseId') {
          this.getApartment().then(res => {
            item.opt.list = res;
          })
        }
      })
      let roleType = this.cache.getLS("userInfo")["roleType"];
      // 非超级管理员 不显示导出
      if(roleType == 1) {
        this.opt.exportButtons = [{
            type: "Export",
            url: this.EXport + "/tenant-service/v1/lease/export",
            listName: "租约列表"
        }]
      }else {
        this.opt.exportButtons.forEach((item,index) => {
          if(item.type == "Export") {
            this.opt.exportButtons.splice(index,1)
          }
        })
      }
      this.rentalStyle = "0";
      this.getMenuName(this.$route.path)
      //学校商户类型的房东不展示租约类型查询条件
      if(this.getSchoolLandlordFlag()) {
        this.opt.search.forEach((item,index) => {
          if(item.key == "rentType") {
            this.opt.search.splice(index,1)
          }
        })
      }
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          // createDate: opt.searchForm.contractorTime ? (this.format(opt.searchForm.contractorTime[0], 1) + '~' +
          //   this.format(opt.searchForm.contractorTime[1], 1)) : null,
          // rentTime: opt.searchForm.rentTime ? (this.format(opt.searchForm.rentTime[0], 1) + '~' + this.format(opt
          //   .searchForm.rentTime[1], 1)) : null,
          // rentType: opt.searchForm.rentType,
          signStartDate: opt.searchForm.contractorTime ? this.format(opt.searchForm.contractorTime[0], 1) : null,
          signEndDate: opt.searchForm.contractorTime ? this.format(opt.searchForm.contractorTime[1], 1) : null,
          startDate: opt.searchForm.rentTime ? this.format(opt.searchForm.rentTime[0], 1) : null,
          endDate: opt.searchForm.rentTime ? this.format(opt.searchForm.rentTime[1], 1) : null,
          leaseState: opt.searchForm.rentStatus ? opt.searchForm.rentStatus : null,
          houseId: opt.searchForm.houseId ? opt.searchForm.houseId : null,
          buildingNo: opt.searchForm.buildingNo ? opt.searchForm.buildingNo : null,
          roomNo: opt.searchForm.roomNo ? opt.searchForm.roomNo : null,
          name: opt.searchForm.name ? opt.searchForm.name : null,
          userPhone: opt.searchForm.userPhone ? opt.searchForm.userPhone : null,
          // ...opt.searchForm,
        };
        //自订长租
        if (opt.searchForm.rentType == '1') {
          dto.leaseFrom = '1';
          dto.leaseType = '2';
        }
        //自订短租
        else if (opt.searchForm.rentType == '2') {
          dto.leaseFrom = '1';
          dto.leaseType = '1';
        }
        //云数排房长租
        else if (opt.searchForm.rentType == '3') {
          dto.leaseFrom = '2';
          dto.leaseType = '2';
        }
        //云数排房短租
        else if (opt.searchForm.rentType == '4') {
          dto.leaseFrom = '2';
          dto.leaseType = '1';
        }
        //自订短租
        else if (opt.searchForm.rentType == '5') {
          dto.leaseFrom = '1';
          dto.leaseType = '3';
        }
        //PMS排房
        else if (opt.searchForm.rentType == '6') {
          dto.leaseFrom = '3';
          dto.leaseType = '4';
        }
        let url = "tenant-service/v1/lease/get-lease-list";
        this.post(url, dto, {
          isUseResponse: true,
          timeout: 30 * 1000 //设置30s超时
        }).then(res => {
          let data = res.data.data.records;
          res.data.total = res.data.data.total;
          res.data.data = data ? data : [];
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.action = [true];
            let typeStr1 = ''
            if (item.leaseFrom == 2) {
              typeStr1 = '排房/';
              // if (item.state == 1) {
              //   item.actions = [true]
              // }
              // item.rentFeeStr = 0;
            } else if (item.leaseFrom == 1) {
              typeStr1 = '自订/';
              // if (item.state == 1) {
              //   item.actions = [true]
              // }
              // if(item.leaseType == 1) {
              //   item.rentFeeStr = item.rentalFee + '元/天'
              // } else if (item.leaseType == 3) {
              //   item.rentFeeStr = item.rentalFee + '元/小时'
              // } else {
              //   item.rentFeeStr = item.rentalFee + '元/月'
              // }
            } else if (item.leaseFrom == 3) {
              typeStr1 = '';
              // item.rentFeeStr = 0;
            }
            // if (item.isCohabit == 2) {
            //   typeStr1 = '同住/';
            //   item.actions = [false]
            // }
            //"leaseType": 1,//租约类型（1：短租；2：长租;0:长租）
            item.reservationTypeStr = typeStr1 + ['长租', '短租', '长租','钟点房','PMS排房'][item.leaseType]
            item.leaseStateStr = this.leaseStatusStr[item.leaseState];
            item.startDateStr = this.format(item.startDate, 1);
            item.checkoutDate = this.format(item.checkoutDate, 1);
            if (this.cache.getLS("userInfo")["roleType"] != "8") {
              item.userPhone = item.userPhone ?
                item.userPhone.substring(0, 3) +
                "****" +
                item.userPhone.substring(7, item.userPhone.length) :
                "--";
            } else {
              item.userPhoneStr = item.userPhone ? item.userPhone : '--';
            }
            item.orderName = item.houseName + item.houseBuilding + item.roomNo + '号房';

          }
          opt.cb(res.data);
        });
      },

      // 合同id获取房间id
      getTalentInfo(id) {
        let dto = {};
        if (this.rentalStyle == "0") {
          dto.contractId = id;
        } else {
          dto.id = id;
        }
        let url =
          this.rentalStyle == "0" ?
          "tenant-service/leasecontract/queryTalentInfo" :
          "landlord-service/shortRent/getShortRent";
        return this.post(url, dto).then(data => {
          return data.roomId || "";
        });
      },
    
      // 获取楼栋
      getBuildList(data) {
        if (!data) {
          return
        }
        let dto = {
          apartmentId: data
        }
        this.post('/landlord-service/building/list', dto).then(res => {
          res = JSON.parse(JSON.stringify(res).replace(/buildName/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/buildCode/g, "value"));
          this.opt.search.forEach(item => {
            if (item.key == 'buildingNo') {
              item.opt.list = res;
            }
          })
        })
      },
      // 批量退房
      batchesCheckOut(item) {
        let notCheckOutFalge = false;
        // 排房和在租的租约才支持批量退房
        item.list.find(it => {
          return notCheckOutFalge = it.leaseFrom != 2 || it.leaseState != 4;
        })
        if (notCheckOutFalge) {
          return this.$message({
            message: "云数排房和在租的租约才支持批量退房。",
            type: "warning"
          })
        } else {
          let dto = {
            leaseIdList: []
          };
          item.list.forEach(it => {
            dto.leaseIdList.push(it.leaseContractId);
          })
          this.post('/tenant-service/leasecontract/batch-quit', dto.leaseIdList, {
            isUseResponse: true,
            isLoading: true,
          }).then(res => {
            if (res.data.code == 0) {
              this.$message({
                message: "批量退房成功",
                type: "success"
              })
              this.$refs.myList.get(); //刷新列表
            }
          })
        }
      },
    }
  };
</script>